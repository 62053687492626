<template>
  <v-container
    fluid
    class="pa-0 ma-0"
    :style="marginTop"
  >
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <title-block :title="detail.title" />
      <v-tabs
        center-active
        show-arrows
        class="mt-4 mb-12 grey--text text--darken-4 cs-tabs-border"
      >
        <v-tabs-slider color="#1B1327"></v-tabs-slider>
        <v-tab class="text-body-1 grey--text text--darken-4 font-weight-medium cs-tab-width" @click="setBranch('main')">{{ main_title }}</v-tab>
        <v-tab class="text-body-1 grey--text text--darken-4 font-weight-medium cs-tab-width" @click="setBranch('1st')">{{ first_title }}</v-tab>
        <v-tab class="text-body-1 grey--text text--darken-4 font-weight-medium cs-tab-width" @click="setBranch('2nd')">{{ second_title }}</v-tab>
        <v-tab class="text-body-1 grey--text text--darken-4 font-weight-medium cs-tab-width" @click="setBranch('3rd')">{{ third_title }}</v-tab>
        <v-tab class="text-body-1 grey--text text--darken-4 font-weight-medium cs-tab-width" @click="setBranch('4th')">{{ fourth_title }}</v-tab>
        <v-tab class="text-body-1 grey--text text--darken-4 font-weight-medium cs-tab-width" @click="setBranch('5th')">{{ fifth_title }}</v-tab>
      </v-tabs>
      <playback-block :items="items" />
    </div>
    <bottom-banner />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { EVENTCENTER, DATACENTER } from '@/datacenter'
import TitleBlock from '@/views/components/TitleBlock'
import PlaybackBlock from '@/views/components/PlaybackBlock'
import BottomBanner from '@/views/components/BottomBanner'
import Tools from '@/mixins/Tools.js'
import Datas from '@/mixins/Datas.js'
export default {
  name: 'Liveroom',
  mixins: [Tools, Datas],
  components: {
    TitleBlock,
    PlaybackBlock,
    BottomBanner
  },
  data() {
    return {
      current_branch: '',
      items: [],
      lives: [],
      venue_tab: 0
    }
  },
  computed: {
    ...mapGetters(['event_id']),
    detail() {
      if (this.lives.length > 0) {
        return this.lives.find((item) => {
          return item.branch === this.current_branch
        })
      }
      return {}
    }
  },
  mounted() {
    this.getLiveDetail(this.event_id)
    this.setBranch('main')
  },
  watch: {
    current_branch(newV) {
      this.getLiveAgenda(this.event_id, newV)
    },
    event_id(newV) {
      this.getLiveDetail(newV)
      this.getLiveAgenda(newV, this.current_branch)
    }
  },
  methods: {
    getLiveDetail(event_id) {
      this.lives = EVENTCENTER.filter((item) => {
        return item.event_id === event_id
      })
    },
    getLiveAgenda(event_id, branch) {
      const tmp_datetime = DATACENTER.filter((item) => {
        return item.event_id === event_id && item.branch === branch && item.time && !item.is_tea && !item.is_lunch && !!item.playback_url
      })
      this.items = tmp_datetime.sort((a, b) => a.title_order - b.title_order)
    },
    // 设置分论坛并提示成功消息
    setBranch(branch) {
      this.current_branch = branch
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-tabs-border
  border-bottom: solid 1px #D8D8D8
.cs-tab-width
  min-width: 70px
</style>
