<template>
  <v-container
    id="banner"
    fluid
    class="pa-0 ma-0"
  >
    <v-row
      no-gutters
      class="pa-0 ma-0"
    >
      <v-col cols="12">
        <v-img
          width="100%"
          :src="is_mobile ? '/images/banner-bm.png?3': '/images/banner-bpc.png?3'"
          class="overflow-y-hidden"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'BottomBanner',
  mixins: [Tools],
}
</script>
